import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import { D2, D3, P1 } from '../theme/typography';
import Spacer from '../components/spacer';
import BottomCut from '../components/bottomCut';

// Asset Imports
import Stripes05 from '../images/stripes/stripes05.inline.svg';

//Styled Components
const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
`;

const PolicyHeading = styled(D3)`
  color: var(--blue1);
  position: relative;
  margin-bottom: 16px;
  text-transform: uppercase;

  span {
    color: var(--accent);
    margin-right: 16px;

    @media (min-width: 800px) {
      position: absolute;
      left: -40px;
      margin-right: 10;
    }
  }
`;

const PolicyText = styled(P1)`
  color: var(--blue1);
  margin-bottom: 32px;
  ul > li {
    list-style-type: disc;
    list-style-position: inside;

    ::marker {
      color: var(--accent);
    }
  }
  strong {
    color: var(--blue2);
    font-family: Poppins;
    font-weight: 900;
  }

  br {
    display: block;
    content: '';
  }

  a {
    text-decoration: underline var(--blue5);
    text-decoration-line: underline;
    -webkit-text-decoration-line: underline;

    &:hover {
      text-decoration-color: var(--accent);
    }
  }
`;

const CookiesPage = () => {
  return (
    <>
      <SEO title='Cookies Policy' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section dark cuts={'bottomCut'}>
        <Container>
          <CenteredHeaderBlock
            data={{
              preHeader: 'Cookies Policy',
              title: 'The Nitty Gritty',
              body: `How we handle cookies when you visit our website.`,
            }}
          />
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts={'topAndBottomCut'}>
        <Container>
          <PolicyContainer>
            <PolicyHeading>
              <span>1.</span>Introduction
            </PolicyHeading>
            <PolicyText>
              This Cookie Policy explains how www.rubberduck.ie ("we," "our," or
              "us") uses cookies on our website. By using our website, you agree
              to the use of cookies as outlined in this policy.
            </PolicyText>
            <PolicyHeading>
              <span>2.</span>What are Cookies?
            </PolicyHeading>
            <PolicyText>
              Cookies are small text files that are stored on your device when
              you visit a website. They help the website recognize your device
              and remember certain information about your visit.
            </PolicyText>
            <PolicyHeading>
              <span>3.</span>Types of Cookies We Use
            </PolicyHeading>
            <PolicyText>
              <strong>Essential Cookies:</strong> These cookies are necessary
              for the proper functioning of our website. They enable you to
              navigate our site and use its features.
              <br />
              <Spacer size={16} />
              <strong>Analytics Cookies:</strong> We use analytics cookies, such
              as those provided by Google Analytics, to collect information
              about how visitors use our website. This information is used to
              analyze and improve our site.
              <br />
              <Spacer size={16} />
              <strong>Functionality Cookies:</strong> These cookies enhance the
              functionality of our website by remembering your preferences, such
              as language preferences and font size.
            </PolicyText>
            <PolicyHeading>
              <span>4.</span>How We Use Cookies
            </PolicyHeading>
            <PolicyText>
              We use cookies for the following purposes:{' '}
              <ul>
                <li>
                  To provide a better user experience by remembering your
                  preferences.
                </li>
                <li>
                  To analyze how visitors use our website and improve its
                  performance.
                </li>
                <li> To enable certain features of the website.</li>
              </ul>
            </PolicyText>
            <PolicyHeading>
              <span>5.</span>Third-Party Cookies
            </PolicyHeading>
            <PolicyText>
              Some cookies on our website may be set by third-party services,
              such as Google Analytics. These third parties may use cookies to
              collect information about your online activities over time and
              across different websites.
            </PolicyText>
            <PolicyHeading>
              <span>6.</span>Your Cookie Choices
            </PolicyHeading>
            <PolicyText>
              You can manage your cookie preferences through your browser
              settings. Most browsers allow you to block or delete cookies.
              However, please note that blocking essential cookies may impact
              the functionality of the website.
            </PolicyText>
            <PolicyHeading>
              <span>7.</span>Changes to the Cookie Policy
            </PolicyHeading>
            <PolicyText>
              We may update our Cookie Policy to reflect changes in our
              practices or for other operational, legal, or regulatory reasons.
              Any changes will be effective immediately upon posting on our
              website.
            </PolicyText>

            <PolicyHeading>
              <span>8.</span>Contact Us
            </PolicyHeading>
            <PolicyText>
              If you have any questions or concerns regarding this Cookies
              Policy, please contact us at:{' '}
              <a href='mailto:info@rubberduck.ie'>info@rubberduck.ie</a>
            </PolicyText>
          </PolicyContainer>
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export default CookiesPage;
